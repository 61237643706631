@import "../../Variables.scss";

.tiblock {
  align-items: center;
  display: flex;
  height: 17px;
}

.ticontainer .tidot {
  background-color: var(--theme-primary);
}

.tidot {
  -webkit-animation: mercuryTypingAnimation 1.5s infinite ease-in-out;
  animation: mercuryTypingAnimation 1.5s infinite ease-in-out;
  border-radius: 50%;
  display: inline-block;
  height: 12px;
  margin-right: 4px;
  width: 12px;
}

@keyframes mercuryTypingAnimation {
  0% {
    -webkit-transform: translateY(0px);
  }
  28% {
    -webkit-transform: translateY(-10px);
  }
  44% {
    -webkit-transform: translateY(0px);
  }
}

@-webkit-keyframes mercuryTypingAnimation {
  0% {
    -webkit-transform: translateY(0px);
  }
  28% {
    -webkit-transform: translateY(-10px);
  }
  44% {
    -webkit-transform: translateY(0px);
  }
}

.tidot:nth-child(1) {
  -webkit-animation-delay: 100ms;
  animation-delay: 100ms;
  background-color: #3D81C7;
}
.tidot:nth-child(2) {
  -webkit-animation-delay: 250ms;
  animation-delay: 250ms;
  background-color: #2A92BE;
}
.tidot:nth-child(3) {
  -webkit-animation-delay: 400ms;
  animation-delay: 400ms;
  background-color: #1A9EB7;
}
