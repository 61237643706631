.propContainer {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr auto auto;
  row-gap: 1rem;
  column-gap: 1rem;
  align-items: end;

  .isSecret {
    margin-bottom: 0.5rem;
  }

  .removeButton {
    margin-bottom: 0.5rem;
  }
}
