@import "./Variables.scss";

body {
  margin: 0;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background;
  min-height: 0;
}

html {
  font-size: 16px;
}

input,
textarea,
button {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--theme-secondary);
  border: 2px solid transparent;
  border-radius: 4px;
}

*::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* Bug fix for katex */
.katex {
  position: relative;
}
