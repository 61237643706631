@import "../../../Variables.scss";

.header {
  img {
    height: 50px;
  }

  .nav {
    a {
      display: block;
      text-decoration: none;
      font-weight: 300;
      padding: 9px 20px 10px 20px;
      border-bottom: 2px solid transparent;
      font-size: 1rem;
      border-radius: 2px;
      min-width: 60px;
      text-align: center;

      button {
        color: var(--neutral-primary);
      }

      button:hover,
      button:focus,
      button:active {
        text-decoration: none !important;
      }

      &.active {
        font-weight: 600;
        border-color: var(--theme-primary);
      }
    }
  }

  .hideInDesktop {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  .hideInMobile {
    display: none !important;
  }

  .showInMobile {
    display: block !important;
  }
}
