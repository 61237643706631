.markdownContainer {
  a {
    color: white !important;
    margin: 0 5px;
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }

  > div {
    display: inline-block;
    padding: 16px;
    border-radius: 4px;
    max-width: 90%;

    .text {
      font-size: 1rem;
      color: var(--black);
      p:first-child {
        margin-top: 0;
      }
      p:last-child {
        margin-bottom: 0;
      }
      img {
        max-width: 100%;
        border-radius: 4px;
      }
      a {
        color: inherit;
        &:active {
          color: inherit;
        }
      }
    }

    button {
      color: var(--black);
      font-size: 1rem;

      &:not(.active) {
        &:hover {
          color: var(--theme-primary);
        }
      }

      &.active {
        color: var(--theme-primary);
      }
    }

    table {
      border-collapse: collapse;
      th {
        color: var(--theme-primary);
      }
      td,
      th {
        padding: 5px;
        border: 1px solid var(--theme-primary);
      }
    }

    section[data-footnotes='true'] {
      padding-top: 10px;
      margin-top: 10px;

      h2 {
        display: none;
      }

      ol {
        display: flex;
        flex-direction: row;
        list-style-position: inside;
        gap: 5px;
        margin: 0;
        padding: 0;
        flex-wrap: wrap;

        li {
          background-color: var(--theme-primary);
          padding: 2px 5px;
          border-radius: 2px;
          font-size: smaller;
          font-weight: 700;

          &:hover {
            opacity: 0.9;
            color: white;
          }

          &::marker {
            color: white !important;
          }

          a {
            padding: 2px 0;
            text-decoration: none;
            color: white !important;

            &.data-footnote-backref {
              display: none;
            }
          }

          p {
            display: inline;
          }
        }
      }

      a[data-footnote-backref='true'] {
        display: none;
      }
    }

    sup {
      display: inline-block;
      border-radius: 2px;
      margin: 0 0 0 4px;
      font-weight: 700;
      font-size: 0.8rem;
      line-height: 0.8rem;
      padding: 2px 0;
      background-color: var(--theme-primary);
      * {
        text-decoration: none !important;
        color: white !important;
      }
      a {
        padding: 0 4px;
      }
      &:hover {
        opacity: 0.9;
        color: white;
      }
    }

    pre {
      border-radius: 6px;
      background-color: var(--theme-primary);
      box-sizing: border-box;

      .code-header {
        padding: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        .left {
          color: #fff;
          font-size: 0.9rem;
          flex: 1 1;
        }
        .right {
          button {
            margin-right: 10px;
            &:last-child {
              margin-right: 0;
            }
            i {
              color: #fff !important;
            }
            &:hover {
              i {
                color: black !important;
              }
            }

            &:active {
              i {
                color: var(--black) !important;
              }
            }
          }
        }
      }

      .code-container {
        background-color: #111;
        padding: 10px;
        overflow-x: auto;
        border-radius: 0px 0px 6px 6px;
        font-size: 0.9rem;

        code {
          color: #fff !important;

          &.wrap {
            text-wrap: pretty;
          }
        }
      }
    }
  }
}
