.properties {
  display: grid;
  row-gap: 1rem;
  margin-bottom: 2rem;

  main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
