.msg-attachment {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  border: 1px solid rgb(from var(--black) r g b / 0.2);
  padding: 5px;
  align-items: center;
  border-radius: 10px;
  margin-top: 5px;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  .icon {
    line-height: 0;
  }

  .name {
    color: var(--black);
  }

  &:hover {
    background-color: rgb(from var(--black) r g b / 0.05);
  }
}
