.gridContainer {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;

  .logoSection {
    margin-top: 2rem;
    display: grid;
    row-gap: 1rem;
    grid-template-rows: 1fr auto;

    section {
      border: 1px solid white;
      width: 100%;
      height: 100%;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;

      img {
        width: 90%;
      }
    }
  }
}
