.buttonSection {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}
