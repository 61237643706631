.maintenance {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 10px;

  .image {
    width: 40%;
    max-width: 100%;
  }
}
