.files {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  padding: 4px;

  .file {
    display: flex;
    flex-direction: row;
    border: 1px solid var(--theme-light);
    border-radius: 4px;
    align-items: center;

    a.file-container {
      display: flex;
      flex-direction: row;
      cursor: pointer;
      gap: 5px;
      padding: 5px 10px 5px 5px;
      align-items: center;
      height: 24px;
      border-radius: 4px 0 0 4px;
      text-decoration: none;

      .icon {
        line-height: 0px;
      }

      .file-name {
        color: var(--theme-light);
      }

      &:hover {
        background-color: rgb(from var(--theme-light) r g b / 0.2);
      }
    }

    button.remove {
      border-left: 1px solid var(--theme-light);
      padding: 5px;
      width: 34px;
      height: 34px;
      text-align: center;
      line-height: 28px;
      cursor: pointer;
      border-radius: 0 4px 4px 0;
      color: var(--theme-light);

      &:hover {
        background-color: rgb(from var(--theme-light) r g b / 0.2);
      }
    }
  }

  button.more {
    flex: unset;
    display: flex;
    flex-direction: row;
    border: 1px dashed var(--theme-light);
    border-radius: 4px;
    align-items: center;
    padding: 5px 10px;
    gap: 10px;
    height: 36px;
    color: var(--theme-light);
    cursor: pointer;
    font-size: 1rem;
    text-wrap: nowrap;
    z-index: 1;

    span {
      z-index: 10;
      &:first-child {
        font-size: 1.1rem;
      }
    }

    &:hover {
      background-color: rgb(from var(--theme-light) r g b / 0.2);

      span {
        color: var(--theme-light);
      }
    }
  }
}
