.gridContainer {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;

  .colorSelection {
    display: grid;
    grid-template-columns: 80px 1rem;
    align-items: end;
    gap: 1rem;
  }

  .logoSection {
    margin-top: 2rem;
    display: grid;
    row-gap: 1rem;
    grid-template-rows: 1fr auto;

    section {
      border: 1px solid white;
      width: 100%;
      height: 100%;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;

      img {
        width: 90%;
      }
    }

    .logoSectionFooter {
      display: grid;
      grid-template-columns: auto 1fr;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;

      div {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        justify-content: flex-start;

        p {
          font-size: 0.6rem;
        }
      }
    }
  }
}
