.node {
  border: 1px solid #ccc;
  padding: 0.5rem;
  margin: 0.5rem;
  display: inline-block;
  vertical-align: top;
  text-align: center;
}

.nodeContent {
  padding: 5px;
}

.children {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
}

.searchFunction {
  border: 1px solid #ff5733;
  margin: 0 0.5rem;
}

.function {
  border: 1px solid #33ff57;
  margin: 0 0.5rem;
}
