@import "../../Variables.scss";

.group-toggle {
  background-color: var(--theme-lighter);
  padding: 4px;
  border-radius: 6px;

  button {
    border-radius: 4px;
    padding: 10px 30px;

    i {
      color: var(--neutral-primary);
    }

    &:active,
    &:focus {
      text-decoration: none !important;
    }

    &:not(.active) span,
    &:not(.active) i {
      color: var(--theme-tertiary);
    }

    &:hover {
      text-decoration: none;
      &:not(.active) span,
      &:not(.active) i {
        color: var(--theme-secondary);
      }
    }

    &.active {
      background-color: var(--theme-primary);

      span,
      i {
        font-weight: 700;
        color: white;
      }
    }
  }
}

.dark-mode {
  .group-toggle {
    background-color: var(--neutral-tertiary-alt);
  }
}
