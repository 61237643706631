.traceMessage {
  .textarea {
    background-color: var(--theme-darker);
    padding: 10px;
    border: 2px solid var(--theme-secondary);
    border-radius: 10px;
    box-sizing: border-box;
    width: 100%;

    textarea {
      width: 100%;
      resize: none;
      box-sizing: border-box;
      background-color: transparent;
      border: none;
      outline: none;
      font-family: inherit;
      flex: 1;
      color: var(--theme-light);
      font-weight: 500;
      font-size: 0.9rem;

      &::placeholder {
        color: var(--theme-light);
      }
    }

    &.active {
      background-color: var(--theme-light);

      textarea {
        color: var(--theme-darker);
      }
    }
  }
}
