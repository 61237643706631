.tool {
  text-align: center;
  width: 280px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  margin: 6px;
  .image {
    background-color: var(--neutral-light);
    border-radius: 10px 10px 0 0;
    padding: 20px;
    img {
      max-width: 100%;
      max-height: 100px;
    }
  }
  .content {
    padding: 20px;
    border-radius: 0 0 10px 10px;
  }
}
