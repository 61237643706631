@import "./../../Variables.scss";

.comboOption {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0.3rem 0 0.6rem;
}

.textarea {
  width: 100%;
  resize: none;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  padding: 4px;
  outline: none;
  font-family: inherit;
  display: flex;
  margin-bottom: 10px;
  background-color: var(--theme-dark-alt);

  .token-count {
    padding: 4px;
    font-weight: 500;
    color: var(--theme-light);
    font-size: 0.7rem;
    cursor: default;
  }

  textarea {
    width: 100%;
    resize: none;
    box-sizing: border-box;
    background-color: transparent;
    border: none;
    padding: 4px;
    outline: none;
    font-family: inherit;
    flex: 1;
    color: var(--theme-light);
    font-weight: 500;
    font-size: 1rem;

    &::placeholder {
      color: var(--theme-light);
    }
  }

  button {
    flex: 1;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.1rem;
    color: var(--theme-light);

    &:hover {
      color: var(--theme-tertiary);
    }
  }
}
