@import './../../../../../../Variables.scss';

.iframe {
  border: 0px;
  width: 100%;
  height: 100%;
}

.noUrlMessage {
  text-align: center;
}
