@import "../../Variables.scss";

.gpt-versions {
  font-size: 1rem;

  .css-347 {
    color: $themePrimary;
  }

  ul {
    list-style: none; /* Remove default bullets */
    padding-left: 20px;
  }

  ul li::before {
    content: "\2022";
    color: $themePrimary;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}
