@import "./../../Variables.scss";

.ai-apps-home {
  .image {
    margin-bottom: -50px;
  }

  .title {
    text-transform: uppercase;
    color: var(--theme-primary);
    margin-bottom: 30px;
  }

  .description {
    margin-bottom: 30px;
  }

  .mesh {
    img {
      max-width: 100%;
    }
  }
}

.dark-mode {
  .ai-apps-home {
    .image {
      img {
        opacity: 0.3 !important;
      }
    }
    .mesh {
      opacity: 0.3 !important;
    }
  }
}
