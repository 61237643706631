h1,
h2,
h3,
pre,
table {
  margin-bottom: 2rem;
}

a {
  color: var(--theme-primary);
}

code,
td {
  font-size: 0.9rem;
}

.codeContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0;
  width: 100%;
  margin-bottom: 1rem;

  .languageContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--theme-primary);
    border: 2px solid var(--theme-primary);

    .language,
    .copyButton {
      background-color: var(--theme-primary);
      color: #fff;
      font-size: 0.9em;
      padding: 0.3rem 0.7rem;
    }

    .copyButton {
      cursor: pointer;
    }
  }

  pre {
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
    border: 2px solid var(--theme-primary);
    background-color: #0d0d0d !important;
    border-radius: 6px;
    white-space: pre-wrap;

    code {
      display: block;
      margin: 0;
      padding: 10px 10px;
    }

    &.withLang {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
  }
}

table {
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    border: 2px solid var(--theme-primary);
    padding: 10px;
    text-align: left;
    vertical-align: top;
  }

  &.table2Cols,
  &.table3Cols {
    th:nth-child(1),
    td:nth-child(1) {
      width: 200px;
    }
  }

  &.table3Cols {
    th:nth-child(2),
    td:nth-child(2) {
      width: 200px;
    }
  }

  &.table4Cols {
    th,
    td {
      width: 25%;
      text-align: center;
      vertical-align: middle;
    }
  }

  &.table5Cols {
    &.permissions {
      th,
      td {
        width: 20%;
        text-align: center;
      }
    }
  }
}
