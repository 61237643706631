.propContainer {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: repeat(3, auto);
  row-gap: 1rem;
  column-gap: 1rem;
  align-items: center;

  .description {
    grid-column: 1 / -1;
  }

  .bottom {
    grid-column: 1 / -1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.separator {
  width: 100%;
  height: 1px;
  background-color: white;
}
