.carousel {
  overflow: hidden;
  .slider {
    padding: 10px 0;
    .items {
      display: flex;
      flex-direction: row;
      gap: 20px;

      &::after {
        content: "";
        flex: 0 0 1px;
        margin-left: -20px;
      }
    }
  }
}

.paging {
  text-align: center;
  button {
    padding: 0;
    i {
      font-size: 14px;
      color: var(--neutral-tertiary-alt);
    }

    &.active {
      i {
        color: var(--theme-primary);
      }
    }
  }
}
