@import "../../Variables.scss";

.gpt-versions {
  .css-347 {
    color: var(--theme-primary);
  }

  ul {
    list-style: none; /* Remove default bullets */
    padding-left: 20px;
  }

  ul li::before {
    content: "\2022";
    color: var(--theme-primary);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  a {
    color: var(--theme-primary);
  }

  img {
    max-width: 350px;
    margin: 0 auto;
    display: block;
  }
}
