@import "../../../Variables.scss";

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .body {
    flex: 1 1;
    display: grid;
    overflow-y: auto;
    overflow: hidden;

    article {
      overflow-y: auto;
      padding: 10px;

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #348174;
        border: 2px solid $background;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-corner {
        background-color: $background;
      }
    }
  }

  .footer {
    flex: 0 50px;
    box-sizing: border-box;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    padding: 1rem;
    font-weight: 300;

    .left {
      flex: 1;
      text-align: left;
    }

    .right {
      flex: 1;
      text-align: right;

      a {
        text-decoration: none;
        color: hsla(0, 0%, 100%, 0.5);

        &.active {
          color: $jti-green;
        }
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .hideInMobile {
    display: none !important;
  }
}
