.table {
  border-collapse: collapse;
  width: 100%;
  th,
  td {
    border: 2px solid var(--theme-primary);
    padding: 10px;
    text-align: left;
    vertical-align: top;
  }
  tr {
    th {
    }
    td {
      text-wrap: nowrap;
      &:nth-child(4) {
        max-width: 40vw;
        text-wrap: unset;
      }
    }
  }
}
