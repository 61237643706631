$themePrimary: #4ab8a5;
$themePrimaryDarker1: #378a7c;
$themePrimaryDarker2: #1b443d;
$neutralPrimary: #ffffff;
$background: #122632;
$chat-input-color: rgba(52, 53, 65);

$chat-bg-color: #0d1c25;
$chat-bg-border-color: rgba($chat-bg-color, 0.9);
$chat-border: #1a3647;
$chat-input-color: rgba(52, 53, 65);
$chat-send-btn: rgb(35, 36, 44);
$chat-send-btn-hover: rgb(24, 25, 31);
$chat-send-btn-active: rgb(14, 15, 19);
$jti-green: #4ab8a5;
